import React from 'react';

import Layout from '../components/Layout';
import Scroll from '../components/Scroll';

import config from '../../config';
import Footer from '../components/Footer';
import SocialLinks from '../components/SocialLinks';
import Contact from '../components/Contact';
import Header from '../components/Header';
import Spaces from '../components/Spaces';

import avatar from '../assets/images/avatar-w.png';

import dev from '../assets/images/devlogo.png';
import design from '../assets/images/design-logo-dark.png';
import music from '../assets/images/music-logo.png';

const IndexPage = () => (
<Layout>

  <Header />

  <header className="mast-home masthead">
    <div className=" d-flex h-100 align-items-center">
      <div className="container d-flex h-100 align-items-center">
        <div className="row mx-auto text-center">
          <h1 className="mx-auto my-0">{config.heading}</h1>
        </div>
      </div>
    </div>
  </header>


  <section id="greeting" className="about-section text-center">
    <div className="container">
      <div className="row">
        <div className="col-lg-8 mx-auto ">
          <h2 className="text-white mb-4">{config.greeting}</h2>
        </div>
      </div>
      <div className="row justify-content-center no-gutters mb-5 mb-lg-0">
        <div className="col-lg-5">
          <p className="text-white">
            {config.greetMsg1} <br /> {config.greetMsg2}
          </p>
        </div>
        <div className="col-lg-4">
          <img className="img-fluid" src={avatar} alt="" />
        </div>
      </div>
    </div>
  </section>

  <Spaces />

  <Contact />

  <SocialLinks />

  <Footer />
  {/*TODO finish footer*/}
  </Layout>
);

export default IndexPage;
