import React from 'react';
import dev from '../assets/images/devlogo.png';
import design from '../assets/images/design-logo-dark.png';
import music from '../assets/images/music-logo.png';

export default function Spaces() {
return (

  <section id="projects" className="spaces-section bg-light">
    <div className="container">
      <div className="row justify-content-center no-gutters mb-5 mb-lg-0">

        <div className="col-lg-4 center">
          <a className="portal" href="/dev">
            <img className="img-fluid" src={dev} alt="" />
            <h1>Dev</h1>
          </a>
        </div>

        <div className="col-lg-4 center">
          <a className="portal" href="/design">
            <img className="img-fluid" src={design} alt="" />
            <h1>Design</h1>
          </a>
        </div>

        <div className="col-lg-4 center">
          <a className="portal" href="/music">
            <img className="img-fluid" src={music} alt="" />
            <h1>Music</h1>
          </a>
        </div>
      </div>
    </div>
  </section>

);
}
